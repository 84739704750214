import {
  Box,
  Button,
  Chip,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
// import { deleteOcspConfiguration } from '../../../store/feature/satpasDevice';
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import {
  useDeleteUserLocalMutation,
  useResetPasswordUserLocalMutation,
} from "../../../store/feature/service/userLocal";
import { useEffect, useState, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const UserTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
  totalData,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalConfig } = useAppSelector(
    (store: RootState) => store.satpasDevice
  );
  const passwordRef = useRef<HTMLInputElement>(null);
  const [deleteUserLocal, { isSuccess, isError }] =
    useDeleteUserLocalMutation();
  const [
    resetPasswordUserLocal,
    {
      isSuccess: isSuccessResetPassword,
      isError: isErrorResetPassword,
      error: errorResetPassword,
      data: dataResetPassword,
    },
  ] = useResetPasswordUserLocalMutation();

  // State to control the modal visibility
  const [openModal, setOpenModal] = useState(false);

  // State to control password visibility
  const [showPassword, setShowPassword] = useState(true);

  // State to manage password input
  const [password, setPassword] = useState("");

  const [copied, setCopied] = useState(false);

  // Function to handle opening the modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setShowPassword(true);
  };

  // Function to toggle password visibility
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    console.log("openModal ===> ", openModal);
    if (passwordRef.current) {
      console.log("Password input is ready:", passwordRef.current);
    } else {
      console.log("Password input ref is null");
    }
  }, [openModal]);

  // Function to copy password to clipboard
  const handleCopyToClipboard = async () => {
    // if (navigator.clipboard) {
    //   navigator.clipboard
    //     .writeText(password)
    //     .then(() => {
    //       console.log("Password copied to clipboard successfully.");
    //       setCopied(true);
    //       setTimeout(() => {
    //         setCopied(false);
    //       }, 1000);
    //     })
    //     .catch((error) => {
    //       console.error("Failed to copy password to clipboard:", error);
    //     });
    // } else {
    //   // Fallback method for older browsers
    //   const textArea = document.createElement("textarea");
    //   textArea.value = password;
    //   textArea.style.position = "fixed"; // Avoid scrolling to bottom
    //   textArea.style.opacity = "0"; // Make it invisible
    //   document.body.appendChild(textArea);
    //   textArea.focus();
    //   textArea.select();
    //   try {
    //     const successful = document.execCommand("copy");
    //     if (successful) {
    //       console.log("Password copied to clipboard using fallback method.");
    //       setCopied(true);
    //       setTimeout(() => {
    //         setCopied(false);
    //       }, 1000);
    //     } else {
    //       console.error("Fallback: Copy command was unsuccessful.");
    //     }
    //   } catch (err) {
    //     console.error("Fallback: Oops, unable to copy", err);
    //   }
    //   document.body.removeChild(textArea);
    // }
    // Create a temporary textarea element
    if (typeof navigator !== "undefined" && navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(password);
        console.log("Password copied to clipboard successfully.");
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      } catch (error) {
        console.error("Failed to copy password to clipboard:", error);
      }
    } else {
      console.log("Clipboard API is not available.");
      // Optionally, implement a fallback method here
      const textArea = document.createElement("textarea");
      textArea.value = password; // Use the password state or variable
      textArea.style.position = "fixed"; // Avoid scrolling to bottom
      textArea.style.opacity = "0"; // Make it invisible
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        if (successful) {
          console.log("Password copied to clipboard successfully.");
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 1000);
        } else {
          console.error("Copy command was unsuccessful.");
        }
      } catch (err) {
        console.error("Oops, unable to copy", err);
      }

      // Remove the temporary textarea element
      document.body.removeChild(textArea);
    }
  };

  useEffect(() => {
    if (isSuccessResetPassword) {
      setPassword(dataResetPassword.password);
      handleOpenModal(); // Open the modal
    }
  }, [isSuccessResetPassword]);
  useEffect(() => {
    if (
      isErrorResetPassword &&
      errorResetPassword &&
      "data" in errorResetPassword
    ) {
      const errorMessage = errorResetPassword.data as { error: string };
      if (errorMessage.error === "error on resend local user invite") {
        Swal.fire({
          icon: "error",
          title: "Error Reset/Generate Password",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#051438",
          text: "You need to assign this user to the User Policy First.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error Reset/Generate Password",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#051438",
          text: "User is not found in the database.",
        });
      }
    }
  }, [isErrorResetPassword, errorResetPassword]);

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onReset = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      // handleOpenModal(); // Open the modal
      resetPasswordUserLocal({ username: params.row.username });
    };

    // REGION: NAVIGATE TO EDIT
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/user/detail/${params.row.id}`, { state: params.row });
    };

    // REGION: DISPATCH DELETE DATA
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this configuration? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteUserLocal(params.row.username);
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onReset} color="gray">
            Reset/Generate Password
          </Button>
          <Button variant="contained" onClick={onEdit}>
            Details
          </Button>
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={onDelete}
          >
            Delete
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "createAt",
      headerName: "Create at",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "loginTime",
      headerName: "Login Time",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];
  useEffect(() => {
    if (dataResetPassword) {
      console.log("dataResetPassword ===> ", dataResetPassword);
    }
  }, [dataResetPassword]);

  console.log("data from userTable ===> ", data);
  // REGION: INIT ROWS
  const rows = data.map((row: any, index: any) => ({
    // uid: row.uc_ocsp_config_uid,
    id: index,
    username: row.username,
    name: row.name,
    email: row.email,
    createAt: row.created_at,
    loginTime: row.login_time,
    loginIp: row.login_ip,
    revision: row.revision,
    localUserId: row.local_user_id,
  }));
  useEffect(() => {
    if (isError) {
      Swal.fire({
        icon: "error",
        title: "Error Deleted",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Delete User Local",
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Deleted",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Delete User Local",
      }).then(() => navigate("/user"));
    }
  }, [isSuccess]);

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          getRowHeight={() => "auto"}
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={totalData}
      />
      {/* Modal for Reset/Generate Password */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>
          Reset/Generate Password{" "}
          <Typography variant="caption" color="green" fontWeight="bold">
            {copied ? "Copied!" : ""}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[900],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="primary">
            New generated password for this user is:
          </DialogContentText>
          <TextField
            inputRef={passwordRef}
            label="Password"
            type={showPassword ? "password" : "text"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            disabled
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                    sx={{ color: "primary.main" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  <IconButton
                    onClick={handleCopyToClipboard}
                    edge="end"
                    sx={{ color: "primary.main" }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiInputLabel-root": { color: "primary.main" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "primary.main",
                },
                "&:hover fieldset": {
                  borderColor: "primary.main",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "primary.main",
                },
                "& input": {
                  color: "primary.main", // Text color
                },
                "&.Mui-disabled": {
                  "& input": {
                    color: "primary.main", // Text color when disabled
                  },
                },
              },
            }}
          />
          <DialogContentText color="primary">
            Make sure that this password has been copied and givento the user
            concerned
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button> */}
          <Button
            onClick={() => {
              /* Add logic to reset/generate password */ handleCloseModal();
            }}
            color="primary"
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserTable;
