import * as yup from "yup";
import {
  validationConfirmNewPasswordL,
  validationEmailField,
  validationNewPassword,
  validationNotEmpty,
} from "./defaultValidations";

export default yup.object({
  name: validationNotEmpty("Name"),
  email: validationEmailField("Email"),
  // password: validationNewPassword("Password", 3, 12),
  // confirmPassword: validationConfirmNewPasswordL("password"),
  roleCode: validationNotEmpty("Role Code"),
});
